import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';

const LateralContact = () => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
            design  {
                icons
            }
              name
              global_email
              global_phone
              global_broker_id
              social{
                facebook
                instagram
                twitter
                youtube
                linkedin
              }
              keys{
                captcha
              }
              branch_office{
                name
                address
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                        form
                    }
                    footer {
                        title_contact
                        title_social
                        title_newsletter
                        title_office
                        button_contact {
                            link
                            value
                        }
                    }
              }
          }
    }`)

    const { design: { icons } } = realEstate
    const iconsStyle = icons === 'Solid' || icons === 'Light' ? `-${icons.toLowerCase()}` : ''

    return (
        
        
        <div class="center-contact">
            <div class="item-contact">
                <h5>{realEstate.sections.footer.title_contact}</h5>
                <a class="hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}>{realEstate.global_email}</a>
                {realEstate?.global_broker_id.map((broker,index) => (
                    <p className={"mb-0 mb-lg-0 " +(broker === ' ' && "d-none")}>{broker}</p>
                ))}
            </div>
            <div className="mb-lg-0">
                {realEstate.branch_office.map((item,index) => (
                    <div class="item-contact">
                        <h5>{realEstate.sections.footer.title_office + " "}{item.name}</h5>
                        <p >{item.address}</p>
                        <p >{item.region}</p>
                        <a target="_blank" className={"hover-link " + (!item.contact.mail && 'd-none')} href={"mailto:"+item.contact.mail}>{item.contact.mail}</a>
                        {item.contact.phone.map(phone => (
                            <p >Teléfono {phone}</p>
                        ))}
                        {item.contact.whatsapp.map(whatsapp => (
                            <a target="_blank" href={"https://api.whatsapp.com/send?" + "phone=+54" + whatsapp.replaceAll("-",'')}  >Whatsapp + {whatsapp}</a>
                        ))}
                        <p className={item.broker_id === '' && 'd-none'}>Matrícula {item.broker_id}</p>
                    </div>
                ))} 
            </div>
            <div class="item-contact">
                <h5 className='mb-3'>{realEstate?.sections?.footer?.title_social}</h5> 
                <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class={`icon-instagram${iconsStyle}`}></i></a>
                <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class={`icon-facebook${iconsStyle}`}></i></a>
                <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class={`icon-linkedin${iconsStyle}`}></i></a>
                <a target="_blank" href={realEstate.social.youtube} class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class={`icon-youtube${iconsStyle}`}></i></a>
                <a target="_blank" href={realEstate.social.twitter} class={realEstate.social.twitter ? "d-inline" : 'd-none'}><i class={`icon-twitter${iconsStyle}`}></i></a>
            </div>
        </div>
        
    )
}

export default LateralContact